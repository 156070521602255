/**
 * Represent data from one completed questionnaire.
 */
import { DataStorage } from '@app/modules/questionnaire/models/data/data-storage.model';
import { Questionnaire } from '@app/modules/questionnaire/models/questionnaire/questionnaire.model';
import { Child } from 'isophi-core';

export class DataSet {
  public constructor(
    public id: number | null,
    public uuid: string,
    public questionnaire: Questionnaire,
    public startedAt: Date,
    public finishedAt: Date,
    public child: Child,
    public dataStorage: DataStorage
  ) {}

  /**
   * Serialize typescript object into basic javascript types.
   */
  public serialize(visibleToTeacher): any {
    return {
      id: this.id,
      uuid: this.uuid,
      questionnaire_id: this.questionnaire.id,
      started_at: this.startedAt.toISOString(),
      finished_at: this.finishedAt.toISOString(),
      child_id: this.child.id,
      child_uuid: this.child.uuid,
      visible_to_teacher: visibleToTeacher,
      items: this.dataStorage.serialize()
    };
  }
}
