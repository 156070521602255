import { Question } from '@app/modules/questionnaire/models/general/question.model';

export class Category {
  public questions: Array<Question> = [];

  public constructor(
    public id: number,
    public uuid: string,
    public name: string,
    public activeIconUrl: string,
    public inactiveIconUrl: string,
    public color: string
  ) {}

  /**
   * Return number of questions in the category.
   */
  public get questionCount(): number {
    return this.questions.length;
  }

  /**
   * Return obtained score of all questions in the category.
   */
  public get score(): number {
    return this.questions.reduce((acc, question) => acc + question.value, 0);
  }

  /**
   * Return maximum possible score of this category.
   */
  public get maxScore(): number {
    return this.questions.reduce((acc, question) => acc + question.maxScore, 0);
  }

  /**
   * Return obtained score (in percent) of all questions in the category.
   */
  public get scorePercent(): number {
    return Math.round((this.score / this.maxScore) * 100);
  }

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   */
  public static deserialize(data: any): Category {
    return new Category(data.id, data.uuid, data.name, data.active_icon_url, data.inactive_icon_url, data.color);
  }

  /**
   * Return category object serialized to basic javascript types.
   */
  public serialize(): any {
    return {
      id: this.id,
      uuid: this.uuid,
      name: this.name,
      active_icon_url: this.activeIconUrl,
      inactive_icon_url: this.inactiveIconUrl,
      color: this.color
    };
  }

  /**
   * Add question into category.
   *
   * @param question
   */
  public addQuestion(question: Question): void {
    this.questions.push(question);
  }
}
