export class Option {
  public constructor(public title: string, public value: number) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   */
  public static deserialize(data: any): Option {
    return new Option(data.title, data.value);
  }
}
