// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { commonEnvironment } from '@env/environment.common';

import { LoggingLevel } from './log-level.enum';

export const environment = {
  envShortcut: 'D',
  production: false,
  logLevel: LoggingLevel.DEBUG,
  common: commonEnvironment,
  client_id: 'p2aOS7wQHuZZq84yxVke7jgd4Y4gyXWhAApBHhVs',
  grant_type: 'password',
  replaysSampleRate: 0,
  tracesSampleRate: 0,

  questionnaireAPI: 'https://api.isophi-merged.doc.endevel.cz/questionnaire/api/v1',
  contractServer: 'https://api.isophi-license.doc.endevel.cz',
  materialServer: 'https://api.isophi-material.doc.endevel.cz',
  questionnaireServer: 'https://api.isophi-questionnaire.doc.endevel.cz',
  sentryDSN: 'https://c65926a9f65c44b38f8147445d03a55a@o1002766.ingest.sentry.io/4505051927347200'
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
