import { Question } from '@app/modules/questionnaire/models/general/question.model';
import { QuestionnaireStep } from '@app/modules/questionnaire/models/passage/questionnaire-step.interface';
import { Questionnaire } from '@app/modules/questionnaire/models/questionnaire/questionnaire.model';

export class Section implements QuestionnaireStep {
  public questions: Array<Question> = new Array<Question>();

  public questionnaire: Questionnaire | null = null;

  public constructor(public id: number, public title: string, public description: string, public imageUrl: string) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   */
  public static deserialize(data: any): Section {
    return new Section(data.id, data.title, data.description, data.image_url);
  }

  /**
   * Add question into section.
   *
   * @param question
   */
  public addQuestion(question: Question) {
    this.questions.push(question);
    question.section = this;
  }

  /**
   * Return route to redirect into, when step is active.
   */
  public getRoute(): string {
    return '/questionnaire/section';
  }

  /**
   * Return index of this section in the questionnaire.
   */
  public getIndex(): number {
    return this.questionnaire.sections.findIndex((e) => {
      return e.id === this.id;
    });
  }

  /**
   * Return order of this section in the questionnaire.
   *
   * It is section index (zero based) + 1
   */
  public getOrder(): number {
    return this.getIndex() + 1;
  }
}
