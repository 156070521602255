/**
 * Class to represent one item in data storage.
 */
export class DataItem {
  public constructor(public dataType: string, public uuid: string, public value: any) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   */
  public static deserialize(data: any): DataItem {
    let value = data.value;
    if (value !== null) value = +value; // convert to number

    return new DataItem(data.data_type, data.source_uuid, value);
  }

  /**
   * Serialize typescript object into basic javascript types.
   */
  public serialize() {
    return {
      data_type: this.dataType,
      source_uuid: this.uuid,
      value: this.value
    };
  }
}
