import { Injectable } from '@angular/core';
import { QuestionType } from '@app/modules/questionnaire/enums/question-type.enum';
import { EvaluationRange } from '@app/modules/questionnaire/models/evaluation/evaluation-range.model';
import { EvaluationRule } from '@app/modules/questionnaire/models/evaluation/evaluation-rule.model';
import { Category } from '@app/modules/questionnaire/models/general/category.model';
import { Option } from '@app/modules/questionnaire/models/general/option.model';
import { OptionQuestion } from '@app/modules/questionnaire/models/general/option-question.model';
import { Question } from '@app/modules/questionnaire/models/general/question.model';
import { TextQuestion } from '@app/modules/questionnaire/models/general/text-question.model';
import { Questionnaire } from '@app/modules/questionnaire/models/questionnaire/questionnaire.model';
import { Section } from '@app/modules/questionnaire/models/questionnaire/section.model';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireDeserializer {
  protected questionnaire: Questionnaire | null = null;

  protected categories: Array<Category> | null = null;

  /**
   * It will deserialize Questionnaire entity.
   */
  public deserialize(data, categories: Array<Category>): Questionnaire {
    this.categories = categories;
    this.deserializeQuestionnaire(data);
    return this.questionnaire;
  }

  /**
   * Deserialize questionnaire.
   *
   * @param data
   */
  protected deserializeQuestionnaire(data: any) {
    this.questionnaire = Questionnaire.deserialize(data);
    this.deserializeSections(data);
    this.deserializeEvaluation(data);
    this.questionnaire.categories = this.categories;
  }

  /**
   * Deserialize questionnaire evaluation.
   *
   * @param data
   */
  protected deserializeEvaluation(data: any) {
    for (const evaluationRuleData of data.evaluation_rules) {
      const rule = EvaluationRule.deserialize(evaluationRuleData);

      for (const evaluationRangeData of evaluationRuleData.ranges) {
        const range = EvaluationRange.deserialize(evaluationRangeData);
        rule.ranges.push(range);
      }

      this.questionnaire.evaluationRules.push(rule);
    }
  }

  /**
   * Deserialize all test sections.
   *
   * @param data
   */
  protected deserializeSections(data: any) {
    for (const sectionData of data.sections) {
      const section = this.deserializeSection(sectionData);
      this.questionnaire.sections.push(section);
      section.questionnaire = this.questionnaire;
    }
  }

  /**
   * Deserialize section.
   *
   * @param data
   */
  protected deserializeSection(data: any): Section {
    const section = Section.deserialize(data);

    for (const questionData of data.questions) {
      const question = this.deserializeQuestion(questionData);
      section.addQuestion(question);
    }

    return section;
  }

  /**
   * Deserialize question.
   *
   * @param data
   */
  protected deserializeQuestion(data: any): Question {
    if (data.question_type === QuestionType.OPTION) {
      return this.deserializeOptionQuestion(data);
    } else if (data.question_type === QuestionType.TEXT) {
      return this.deserializeTextQuestion(data);
    } else {
      throw new Error('Unknown question type "' + data.question_type + '".');
    }
  }

  /**
   * Deserialize question of type OptionQuestion.
   *
   * @param data
   */
  protected deserializeOptionQuestion(data: any): OptionQuestion {
    const category = this.getCategory(data.category_id);
    const question = OptionQuestion.deserialize(data, category);
    category.addQuestion(question);

    for (const optionData of data.options) {
      const option = Option.deserialize(optionData);
      question.options.push(option);
    }

    return question;
  }

  /**
   * Deserialize question of type TextQuestion.
   *
   * @param data
   */
  protected deserializeTextQuestion(data: any): TextQuestion {
    const category = this.getCategory(data.category_id);
    return TextQuestion.deserialize(data, category);
  }

  /**
   * Helper method to return category by ID.
   *
   * @param id
   */
  protected getCategory(id: number): Category | null {
    const category = this.categories.find((c) => c.id === id);
    return category === undefined ? null : category;
  }
}
