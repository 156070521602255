import { QuestionnaireStep } from '@app/modules/questionnaire/models/passage/questionnaire-step.interface';
import { Questionnaire } from '@app/modules/questionnaire/models/questionnaire/questionnaire.model';

export class QuestionnaireIntroStep implements QuestionnaireStep {
  public constructor(public questionnaire: Questionnaire) {}

  /**
   * Return route to redirect into, when step is active.
   */
  public getRoute(): string {
    return '/questionnaire/intro';
  }
}
