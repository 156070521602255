import { DataItem } from '@app/modules/questionnaire/models/data/data-item.model';

/**
 * Class to store test data.
 */
export class DataStorage {
  public data: Map<string, DataItem> = new Map<string, DataItem>();

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   */
  public static deserialize(data: any): DataStorage {
    const storage = new DataStorage();

    for (const itemData of data) {
      const item = DataItem.deserialize(itemData);
      storage.data.set(item.uuid, item);
    }

    return storage;
  }

  /**
   * Serialize typescript object into basic javascript types.
   *
   * It returns array of all items saved in data storage.
   */
  public serialize(): any {
    const items = [];
    this.data.forEach((value) => items.push(value.serialize()));
    return items;
  }

  /**
   * Set data into data storage.
   *
   * @param dataType
   * @param uuid
   * @param value
   */
  public setData(dataType: string, uuid: string, value: any): void {
    this.data.set(uuid, new DataItem(dataType, uuid, value));
  }

  /**
   * Get data from data storage.
   *
   * @param uuid
   * @return any
   */
  public getData(uuid: string): DataItem | null {
    const dataItem = this.data.get(uuid);
    return dataItem === undefined ? null : dataItem;
  }

  /**
   * Check if there is any data for uuid.
   *
   * @param uuid
   */
  public hasData(uuid: string): boolean {
    return this.data.get(uuid) !== undefined;
  }

  /**
   * Find data item in data storage and return only value.
   *
   * @param uuid
   * @return any
   */
  public getDataValue(uuid: string): any {
    const dataItem = this.data.get(uuid);
    return dataItem === undefined ? null : dataItem.value;
  }
}
