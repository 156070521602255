import { EvaluationRange } from '@app/modules/questionnaire/models/evaluation/evaluation-range.model';

export class EvaluationRule {
  public ranges: Array<EvaluationRange> = new Array<EvaluationRange>();

  public constructor(public id: number, public minAge: number, public maxAge: number) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   */
  public static deserialize(data: any): EvaluationRule {
    return new EvaluationRule(data.id, data.min_age, data.max_age);
  }

  /**
   * Check if rule is suitable for child age.
   *
   * @param ageInDays
   */
  public isSuitableForAge(ageInDays: number): boolean {
    const minAgePass = this.minAge === null || this.minAge <= ageInDays;
    const maxAgePass = this.maxAge === null || ageInDays <= this.maxAge;

    return minAgePass && maxAgePass;
  }
}
