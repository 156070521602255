import { QuestionnaireMode } from '@app/modules/questionnaire/enums/questionnaire-mode.enum';
import { DataStorage } from '@app/modules/questionnaire/models/data/data-storage.model';
import { EvaluationRule } from '@app/modules/questionnaire/models/evaluation/evaluation-rule.model';
import { Category } from '@app/modules/questionnaire/models/general/category.model';
import { QuestionnairePassage } from '@app/modules/questionnaire/models/passage/questionnaire-passage.model';
import { Section } from '@app/modules/questionnaire/models/questionnaire/section.model';
import { ChildAgeFormStep } from '@app/modules/questionnaire/models/steps/child-age-form-step.model';
import { ContractsStep } from '@app/modules/questionnaire/models/steps/contracts-step.model';
import { QuestionnaireIntroStep } from '@app/modules/questionnaire/models/steps/questionnaire-intro-step.model';
import { Child } from 'isophi-core';

export class Questionnaire {
  public sections: Array<Section> = new Array<Section>();

  public questionnairePassage: QuestionnairePassage | null = null;

  public running = false;

  public completed = false;

  public mode: string | null = null;

  public child: Child | null = null;

  public evaluationRules: Array<EvaluationRule> = new Array<EvaluationRule>();

  public categories: Array<Category> = new Array<Category>();

  protected _questionCount: number = null;

  public constructor(
    public id: number,
    public uuid: string,
    public name: string,
    public introText: string,
    public completedText: string,
    public ageForm: boolean,
    public minDuration: number,
    public maxDuration: number,
    public minAge: number | null,
    public maxAge: number | null,
    public minAgeWarning: string | null,
    public maxAgeWarning: string | null
  ) {}

  /**
   * Return number of questions in the questionnaire.
   */
  public get questionCount(): number {
    if (this._questionCount === null) {
      this._questionCount = this.sections.reduce((acc, section) => {
        return acc + section.questions.length;
      }, 0);
    }
    return this._questionCount;
  }

  /**
   * Return number of answered questions in the questionnaire.
   */
  public get answeredQuestionCount(): number {
    return this.sections.reduce((acc, section) => {
      return (
        acc +
        section.questions.reduce((questionAcc, question) => {
          return questionAcc + (question.answered ? 1 : 0);
        }, 0)
      );
    }, 0);
  }

  /**
   * Return progress in questionnaire in percent.
   */
  public get progressInPercent(): number {
    if (this.questionCount === 0) return 0;
    return Math.round((this.answeredQuestionCount / this.questionCount) * 100);
  }

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   */
  public static deserialize(data: any): Questionnaire {
    return new Questionnaire(
      data.id,
      data.uuid,
      data.name,
      data.intro_text,
      data.completed_text,
      data.age_form,
      data.min_duration,
      data.max_duration,
      data.min_age,
      data.max_age,
      data.min_age_warning,
      data.max_age_warning
    );
  }

  /**
   * Start new questionnaire, that is prepare questionnaire entity data for new questionnaire.
   */
  public startQuestionnaire(mode: QuestionnaireMode): void {
    this.questionnairePassage = new QuestionnairePassage(this);
    this.registerQuestionnaireSteps();
    this.running = true;
    this.mode = mode;
  }

  public isMultiSections(): boolean {
    return this.sections.length > 1;
  }

  /**
   * Return true if questionnaire is in filling mode, else false.
   */
  public isFillingMode(): boolean {
    return this.mode === QuestionnaireMode.FILLING;
  }

  /**
   * Check if child is older than min age of this questionnaire.
   *
   * Method use childAge property to check child age.
   */
  public isMinAgeOk(): boolean {
    if (this.minAge === null) return true;
    return this.child.ageInDays >= this.minAge;
  }

  /**
   * Check if child is younger than max age of this questionnaire.
   *
   * Method use childAge property to check child age.
   */
  public isMaxAgeOk(): boolean {
    if (this.maxAge === null) return true;
    return this.child.ageInDays <= this.maxAge;
  }

  /**
   * Return all questionnaire data, ie. it returns all answers as DataStorage.
   */
  public getData(): DataStorage {
    const storage = new DataStorage();

    for (const section of this.sections) {
      for (const question of section.questions) {
        if (!question.answered) continue;
        storage.setData(question.dataType, question.uuid, question.value);
      }
    }

    return storage;
  }

  /**
   * Restore all answers from DataStorage.
   *
   * @param storage
   */
  public setData(storage: DataStorage): void {
    for (const section of this.sections) {
      for (const question of section.questions) {
        if (!storage.hasData(question.uuid)) continue;

        question.answered = true;
        question.value = storage.getDataValue(question.uuid);
      }
    }
  }

  /**
   * Register all steps of this questionnaire.
   */
  protected registerQuestionnaireSteps() {
    // register intro and age form
    this.questionnairePassage.registerStep(new QuestionnaireIntroStep(this));
    if (this.ageForm) {
      this.questionnairePassage.registerStep(new ChildAgeFormStep(this));
    }

    // register all sections and questions
    for (const section of this.sections) {
      this.questionnairePassage.registerStep(section);
      for (const question of section.questions) {
        this.questionnairePassage.registerStep(question);
      }
    }

    this.questionnairePassage.registerStep(new ContractsStep(this));
  }
}
