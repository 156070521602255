import { Injectable } from '@angular/core';
import { Contract } from '@app/modules/application/models/contract.model';
import { ContractObject } from '@app/modules/application/models/contract-object.model';
import { ApplicationService } from '@app/modules/application/services/application.service';
import { environment } from '@env/environment';
import { HttpService } from 'isophi-core';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  public constructor(protected httpService: HttpService, protected appService: ApplicationService) {}

  /**
   * Save contract consent to server.
   *
   * @param contract
   * @param contractObjects
   */
  public saveContractConsent(contract: Contract, contractObjects: Array<ContractObject>): Promise<any> {
    const url = `${environment.questionnaireAPI}/contract-consents/`;
    const postData = new Map<string, any>([
      ['contract_keyword', contract.keyword],
      ['version', contract.version],
      ['contract_objects', contractObjects.map((co) => co.serialize())]
    ]);
    return this.httpService.post(url, postData, this.appService.app.securityToken).toPromise();
  }

  /**
   * Upload data to server.
   *
   * @param requestMethod
   * @param endpointURL
   * @param data
   */
  public async uploadData(requestMethod, endpointURL, data) {
    return this.httpService
      .request(requestMethod, endpointURL, data)
      .toPromise()
      .then((response) => {
        return response.body;
      });
  }
}
