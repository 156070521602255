import { QuestionnaireStep } from '@app/modules/questionnaire/models/passage/questionnaire-step.interface';
import { Questionnaire } from '@app/modules/questionnaire/models/questionnaire/questionnaire.model';

export class QuestionnairePassage {
  public steps: Array<QuestionnaireStep> = new Array<QuestionnaireStep>();

  public activeStepIndex = 0;

  public lastAction: string | null = null;

  public constructor(public questionnaire: Questionnaire) {}

  /**
   * Return active step.
   */
  public get activeStep(): QuestionnaireStep {
    return this.steps[this.activeStepIndex];
  }

  /**
   * Register new step into questionnaire passage.
   *
   * @param step
   */
  public registerStep(step: QuestionnaireStep): void {
    this.steps.push(step);
  }

  /**
   * Activate step by index.
   *
   * @param index
   */
  public activateStepByIndex(index: number): void {
    this.activeStepIndex = index;
  }

  /**
   * Return true/false if questionnaire has next step.
   */
  public hasNextStep(): boolean {
    const lastIndex = this.steps.length - 1;
    return this.activeStepIndex < lastIndex;
  }

  /**
   * Return true/false if questionnaire has previous step.
   */
  public hasPreviousStep(): boolean {
    return this.activeStepIndex > 0;
  }

  /**
   * Move questionnaire state to next step. Return true/false if activation was ok,
   * i.e. there was next step.
   */
  public nextStep(): boolean {
    this.lastAction = 'next';
    const nextIndex = this.activeStepIndex + 1;
    if (nextIndex >= this.steps.length) return false;
    this.activeStepIndex = nextIndex;
    return true;
  }

  /**
   * Move questionnaire state to previous step. Return true/false if activation was ok,
   * i.e. there was previous step.
   */
  public previousStep(): boolean {
    this.lastAction = 'prev';
    const previousIndex = this.activeStepIndex - 1;
    if (previousIndex < 0) return false;
    this.activeStepIndex = previousIndex;
    return true;
  }
}
