import { Injectable } from '@angular/core';
import { Logger } from '@app/core/utils/logger';
import { ApplicationService } from '@app/modules/application/services/application.service';
import { environment } from '@env/environment';
import { TranslocoService } from '@ngneat/transloco';
import { Child, User } from 'isophi-core';

/**
 * Service to manage communication with other iSophi systems,
 * when questionnaire running in iframe.
 *
 * iSophi message format:
 * {'key': 'msg_keyword', 'data': 'any_data_for_msg_type', 'provider': 'iSophi', 'isophi_app': 'app-keyword'}
 */
@Injectable({
  providedIn: 'root'
})
export class MessageService {
  public constructor(protected appService: ApplicationService, private i18n: TranslocoService) {}

  /**
   * Method to receive messages from other app.
   */
  public messageReceiver = (event) => {
    let messageData = null;

    try {
      messageData = JSON.parse(event.data);
    } catch (e) {
      // do not process message in not iSophi format.
      return;
    }

    if (
      typeof messageData !== 'object' ||
      typeof messageData.key === 'undefined' ||
      typeof messageData.data === 'undefined' ||
      typeof messageData.provider === 'undefined' ||
      typeof messageData.isophi_app === 'undefined'
    ) {
      // Process message only from iSophi apps in iSophi format
      return;
    }

    if (typeof messageData.provider !== 'string' && messageData.provider !== 'iSophi') {
      // Process message only from iSophi apps
      return;
    }

    const key = messageData.key;
    const data = messageData.data;

    if (key === 'child') {
      this.receiveChild(data);
    } else if (key === 'security_token') {
      this.receiveSecurityToken(data);
    } else if (key === 'logged_user') {
      this.receiveLoggedUser(data.user, data.type);
    } else if (key === 'questionnaire_type') {
      this.receiveQuestionnaireType(data);
    } else if (key === 'language') {
      this.receiveLanguage(data);
    } else {
      throw Error(`Unknown message type with key: ${key}`);
    }
  };

  /**
   * Init listener for communication from other app.
   */
  public initMessageListener(): void {
    Logger.debug('MessageService :: message listener initialized');
    window.addEventListener('message', this.messageReceiver, false);
  }

  /**
   * Send navigate message to parent.
   *
   * @param destination
   */
  public sendNavigateMessage(destination: string): void {
    window.parent.postMessage(
      JSON.stringify({
        key: 'navigate',
        data: {
          destination
        },
        isophi_app: environment.common.appKeyword,
        provider: 'iSophi'
      }),
      '*'
    );
  }

  /**
   * Method to receive child data and deserialize it.
   */
  protected receiveChild(childData: Record<string, unknown>): void {
    this.appService.app.child = Child.deserialize(childData);
  }

  /**
   * Method to receive security token for communication with server.
   */
  protected receiveSecurityToken(token: string): void {
    this.appService.app.securityToken = token;
  }

  /**
   * Method to receive security token for communication with server.
   */
  protected receiveQuestionnaireType(questionnaireType: string): void {
    this.appService.app.questionnaireType = questionnaireType;
  }

  /**
   * Method to receive security token for communication with server.
   */
  protected receiveLanguage(lang: string): void {
    this.i18n.setActiveLang(lang);
  }

  /**
   * Method to receive logged user data.
   */
  protected receiveLoggedUser(userData: any, type: string): void {
    this.appService.app.loggedUser = User.deserialize(userData);
    this.appService.app.loggedUserType = type;
  }
}
