import { Injectable } from '@angular/core';
import { Application } from '@app/modules/application/models/application.model';
import { ApplicationService } from '@app/modules/application/services/application.service';
import { environment } from '@env/environment';
import { IsophiCoreService } from 'isophi-core';

@Injectable({
  providedIn: 'root'
})
export class InitService {
  public constructor(protected applicationService: ApplicationService, protected isophiCoreService: IsophiCoreService) {}

  /**
   * Init application.
   */
  public async initApplication() {
    const app = this.applicationService.app;
    if (app === null) this.createApp();

    // init isophi-core library
    this.isophiCoreService.setUpRequiredFields(
      environment.common.version,
      environment.common.appKeyword,
      environment.client_id,
      environment.questionnaireAPI
    );

    return Promise.resolve();
  }

  /**
   * Create new instance of the Application.
   */
  protected createApp() {
    this.applicationService.app = new Application();
  }
}
