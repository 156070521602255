import { Component } from '@angular/core';
import { MessageService } from '@app/shared/services/message.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public constructor(messageService: MessageService) {
    messageService.initMessageListener();
  }
}
