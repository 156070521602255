import { Category } from '@app/modules/questionnaire/models/general/category.model';
import { Option } from '@app/modules/questionnaire/models/general/option.model';
import { Question } from '@app/modules/questionnaire/models/general/question.model';

export class OptionQuestion extends Question {
  public options: Array<Option> = new Array<Option>();

  public constructor(
    id: number,
    uuid: string,
    title: string,
    description: string,
    category: Category,
    required: boolean,
    skippable: boolean,
    public multipleOptions: boolean
  ) {
    super(id, uuid, title, description, category, required, skippable);
  }

  /**
   * Return maximum possible score for this question.
   */
  public get maxScore() {
    return Math.max(...this.options.map((x) => x.value));
  }

  /**
   * Return unique string representing one data type (source).
   */
  public get dataType() {
    return 'question$option';
  }

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param category
   */
  public static deserialize(data: any, category: Category): OptionQuestion {
    return new OptionQuestion(
      data.id,
      data.uuid,
      data.title,
      data.description,
      category,
      data.required,
      data.skippable,
      data.multiple_options
    );
  }

  /**
   * Return route to redirect into, when step is active.
   */
  public getRoute(): string {
    return '/questionnaire/option-question';
  }
}
