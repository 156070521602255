import { Category } from '@app/modules/questionnaire/models/general/category.model';
import { QuestionnaireStep } from '@app/modules/questionnaire/models/passage/questionnaire-step.interface';
import { Section } from '@app/modules/questionnaire/models/questionnaire/section.model';

export abstract class Question implements QuestionnaireStep {
  public value: number | null = null;

  public answered = false;

  public section: Section | null = null;

  protected constructor(
    public id: number,
    public uuid: string,
    public title: string,
    public description: string,
    public category: Category,
    public required: boolean,
    public skippable: boolean
  ) {}

  /**
   * Return maximum possible score for this question.
   */
  public abstract get maxScore();

  /**
   * Return unique string representing one data type (source).
   */
  public abstract get dataType();

  /**
   * Return index of this question in the section.
   */
  public getIndex(): number {
    return this.section.questions.findIndex((e) => {
      return e.uuid === this.uuid;
    });
  }

  /**
   * Return order of this question in the section.
   *
   * It is question index (zero based) + 1
   */
  public getOrder(): number {
    return this.getIndex() + 1;
  }
  /**
   * Return route to redirect into, when step is active.
   */
  public abstract getRoute(): string;
}
