import { Injectable } from '@angular/core';
import { HttpMethod } from '@app/core/enums/http-method.enum';
import { Logger } from '@app/core/utils/logger';
import { ApplicationService } from '@app/modules/application/services/application.service';
import { QuestionnaireDeserializer } from '@app/modules/questionnaire/deserializers/questionnaire.deserializer';
import { QuestionnaireMode } from '@app/modules/questionnaire/enums/questionnaire-mode.enum';
import { DataSet } from '@app/modules/questionnaire/models/data/data-set.model';
import { Category } from '@app/modules/questionnaire/models/general/category.model';
import { Questionnaire } from '@app/modules/questionnaire/models/questionnaire/questionnaire.model';
import { UploadService } from '@app/modules/sync/services/upload.service';
import { environment } from '@env/environment';
import { HttpService } from 'isophi-core';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {
  public questionnaire: Questionnaire | null = null;

  public questionnaireData: DataSet | null = null;

  public startedAt: Date | null = null;

  public visibleToTeacher = false;

  public constructor(
    protected httpService: HttpService,
    protected questionnaireDeserializer: QuestionnaireDeserializer,
    protected uploadService: UploadService,
    protected appService: ApplicationService
  ) {}

  /**
   * It will download and create questionnaire.
   */
  public async initQuestionnaire() {
    return this.downloadCategories()
      .then((categories) => this.downloadQuestionnaire(categories))
      .then(() => {
        Logger.debug('QuestionnaireService :: questionnaire loaded.');
        Logger.debugWithParams('QuestionnaireService :: Loaded questionnaire:', [this.questionnaire]);
      })
      .catch((reason) => {
        Logger.debugWithParams('QuestionnaireService :: Unable to init questionnaire.', [reason]);
        throw reason;
      });
  }

  /**
   * It finish questionnaire and save data of completed questionnaire.
   */
  public finishQuestionnaire() {
    this.questionnaire.running = false;
    this.questionnaire.completed = true;
    this.questionnaireData = this.createDataSet();
  }

  /**
   * Start questionnaire.
   */
  public startQuestionnaire() {
    if (this.questionnaire === null) {
      Logger.debug('QuestionnaireService :: questionnaire does not exist.');
      throw Error('Cannot start questionnaire, questionnaire does not exist.');
    }

    this.questionnaire.startQuestionnaire(QuestionnaireMode.FILLING);
    this.startedAt = new Date();
  }

  /**
   * Upload completed questionnaire into server.
   */
  public uploadQuestionnaire(): Promise<any> {
    if (this.questionnaireData === null) {
      return Promise.reject('There is no questionnaire data. You should call finishQuestionnaire() before uploadQuestionnaire().');
    }
    const uploadUrl = environment.questionnaireAPI + '/data-sets/';
    const data = new Map<string, any>(Object.entries(this.questionnaireData.serialize(this.visibleToTeacher)));
    data.set('child_birth_date', this.getChildBirthDateString());
    data.set('respondent_type', this.appService.app.loggedUserType);
    data.set('respondent_id', this.appService.app.loggedUser.parent.id);
    data.set('user_uuid', this.appService.app.loggedUser.uuid);
    data.set('visible_to_teacher', this.visibleToTeacher);
    data.delete('id'); // id is null before sending to server, so delete it.

    return this.uploadService
      .uploadData(HttpMethod.POST, uploadUrl, data)
      .then((value: any) => {
        Logger.debug('QuestionnaireService :: Questionnaire data sent to server.');
        this.questionnaire.id = value.id;
        return value;
      })
      .catch((reason) => {
        Logger.debug('QuestionnaireService :: Unable to sent questionnaire data to server. Reason: ' + reason);
        throw reason;
      });
  }

  /**
   * Download psychological categories required for questionnaire.
   */
  protected downloadCategories(): Promise<Category[]> {
    const categoriesUrl = environment.questionnaireAPI + '/questionnaire/' + this.appService.app.questionnaireType + '/categories/';
    return this.httpService
      .get(categoriesUrl, this.appService.app.securityToken)
      .toPromise()
      .then((categoriesResponse) => {
        const categories = new Array<Category>();
        for (const categoryData of categoriesResponse.body.results) {
          categories.push(Category.deserialize(categoryData));
        }
        return categories;
      });
  }

  /**
   * Download questionnaire.
   *
   * @param categories
   */
  protected downloadQuestionnaire(categories: Category[]): Promise<void> {
    const questionnaireUrl = environment.questionnaireAPI + '/questionnaire/' + this.appService.app.questionnaireType + '/';
    return this.httpService
      .get(questionnaireUrl, this.appService.app.securityToken)
      .toPromise()
      .then((questionnaireResponse) => {
        this.questionnaire = this.questionnaireDeserializer.deserialize(questionnaireResponse.body, categories);
      });
  }

  /**
   * Create data set object with data of completed questionnaire.
   */
  protected createDataSet(): DataSet {
    return new DataSet(
      null,
      this.appService.app.dataSetUuid,
      this.questionnaire,
      this.startedAt,
      new Date(),
      this.questionnaire.child,
      this.questionnaire.getData()
    );
  }

  protected getChildBirthDateString(): string | null {
    const child = this.questionnaire.child;
    if (child === null) return null;
    const birthDate = this.questionnaire.child.birthDate;
    if (birthDate === null) return null;
    const year = birthDate.getFullYear();
    const month = birthDate.getMonth() + 1;
    const day = birthDate.getDate();
    return `${year}-${month}-${day}`;
  }
}
