import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from '@app/modules/application/services/application.service';
import { QuestionnaireService } from '@app/modules/questionnaire/services/questionnaire.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  /**
   * Version of the application.
   */
  public version = '';

  /**
   * Shortcut of environment type (prod/dev/techsophia/...)
   */
  public envShortcut = '';

  public constructor(
    public router: Router,
    protected appService: ApplicationService,
    protected questionnaireService: QuestionnaireService
  ) {}

  public ngOnInit(): void {
    this.version = environment.common.version;
    this.envShortcut = environment.envShortcut;
  }

  /**
   * Print Application class into console, if NOT in production mode.
   */
  public debugApplication(): void {
    if (environment.production) return;
    console.log(this.appService.app);
  }
}
