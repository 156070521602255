import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeCs from '@angular/common/locales/cs';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { InitService } from '@app/modules/application/services/init.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import * as Sentry from '@sentry/angular-ivy';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { TranslocoRootModule } from './transloco-root.module';

registerLocaleData(localeCs, 'cs');

export const initApplication = (initService: InitService) => () => initService.initApplication();

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule.forRoot(),
    AppRoutingModule,
    NgxSpinnerModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    TranslocoRootModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler()
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    { provide: LOCALE_ID, useValue: 'cs' },
    {
      provide: APP_INITIALIZER,
      useFactory: initApplication,
      multi: true,
      deps: [InitService, Sentry.TraceService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
