import { environment } from '@env/environment';

/**
 * Class to log information.
 */
export class Logger {
  /**
   * Log error message.
   *
   * @param msg
   */
  public static error(msg: string): void {
    if (environment.logLevel >= 1) {
      console.log('Error: ' + msg);
    }
  }

  /**
   * Log warning message.
   *
   * @param msg
   */
  public static warning(msg: string): void {
    if (environment.logLevel >= 2) {
      console.log('Warning: ' + msg);
    }
  }

  /**
   * Log debug message.
   *
   * @param msg
   */
  public static debug(msg: string): void {
    if (environment.logLevel >= 3) {
      console.log('Debug: ' + msg);
    }
  }

  /**
   * Log debug message.
   *
   * @param msg
   * @param params
   */
  public static debugWithParams(msg: string, params: Array<any>): void {
    if (environment.logLevel >= 3) {
      console.log('Debug: ' + msg);
      for (const param of params) {
        console.log(param);
      }
    }
  }

  /**
   * Log message with specific level.
   *
   * @param level
   * @param msg
   */
  public static log(level: number, msg: string): void {
    if (environment.logLevel >= level) {
      console.log(msg);
    }
  }
}
