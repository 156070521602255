<ng-container id="main-container">
  <router-outlet></router-outlet>
</ng-container>

<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  color="#fff"
  size="large"
  type="ball-pulse"
>Loading ...
</ngx-spinner>
