import { Category } from '@app/modules/questionnaire/models/general/category.model';
import { Question } from '@app/modules/questionnaire/models/general/question.model';

export class TextQuestion extends Question {
  /**
   * Return maximum possible score for this question.
   */
  public get maxScore() {
    throw new Error('Not implemented.');
  }

  /**
   * Return unique string representing one data type (source).
   */
  public get dataType() {
    return 'question$text';
  }

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param category
   */
  public static deserialize(data: any, category: Category): TextQuestion {
    return new TextQuestion(data.id, data.uuid, data.title, data.description, category, data.required, data.skippable);
  }

  /**
   * Return route to redirect into, when step is active.
   */
  public getRoute(): string {
    return '/questionnaire/text-question';
  }
}
