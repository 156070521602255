/* eslint-disable @typescript-eslint/member-ordering */
import { Contract } from '@app/modules/application/models/contract.model';
import { Child, Kindergarten, User } from 'isophi-core';
import { BehaviorSubject } from 'rxjs';
import { v4 as uuid } from 'uuid';

const DEFAULT_QUESTIONNAIRE_TYPE = '4';

export class Application {
  /**
   * UUID for final questionnaire DataSet.
   * We need DataSet UUID before we generate DataSet to save processing law contracts.
   */
  public dataSetUuid: string;

  public kindergarten: Kindergarten | null = null;

  public loggedUserType: string | null = null;

  public loggedUserChanged: BehaviorSubject<User | null>;

  public childChanged: BehaviorSubject<Child | null>;

  public securityTokenChanged: BehaviorSubject<string | null>;

  public evaluationContract: Contract | null = null;

  public childRecommendationContract: Contract | null = null;

  public saveQuestionnaireResultContract: Contract | null = null;

  public resultKindergartenSharingContract: Contract | null = null;

  public academicResearchContract: Contract | null = null;

  public _loggedUser: User | null = null;

  protected _child: Child | null = null;

  protected _securityToken: string | null = null;

  protected _questionnaireType: string = DEFAULT_QUESTIONNAIRE_TYPE;

  public constructor() {
    this.dataSetUuid = uuid();

    this.childChanged = new BehaviorSubject<Child | null>(this._child);
    this.securityTokenChanged = new BehaviorSubject<string | null>(this._securityToken);
    this.loggedUserChanged = new BehaviorSubject<User | null>(this._loggedUser);
  }

  public get loggedUser(): User | null {
    return this._loggedUser;
  }

  public set loggedUser(user: User) {
    this._loggedUser = user;
    this.loggedUserChanged.next(user);
  }

  public get child(): Child | null {
    return this._child;
  }

  public set child(child: Child) {
    this._child = child;
    this.childChanged.next(child);
  }

  public get securityToken(): string {
    return this._securityToken;
  }

  public set securityToken(securityToken: string) {
    this._securityToken = securityToken;
    this.securityTokenChanged.next(securityToken);
  }

  public get questionnaireType(): string {
    return this._questionnaireType;
  }

  public set questionnaireType(questionnaireType: string) {
    this._questionnaireType = questionnaireType;
  }

  public isSecurityTokenSet(): boolean {
    return this._securityToken !== null;
  }

  public isChildSet(): boolean {
    return this._child !== null;
  }
}
