export class EvaluationRange {
  public constructor(
    public id: number,
    public title: string,
    public textColor: string,
    public backgroundColor: string,
    public minPercent: number,
    public maxPercent: number,
    public imageUrl: string
  ) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   */
  public static deserialize(data: any): EvaluationRange {
    return new EvaluationRange(
      data.id,
      data.title,
      data.text_color,
      data.background_color,
      data.min_percent,
      data.max_percent,
      data.image_url
    );
  }

  /**
   * Check if score is suitable for this range.
   * (That is score is between min and max percent of this range)
   *
   * @param score   Score in percent
   */
  public isSuitableForScore(score: number): boolean {
    return score <= this.maxPercent && score >= this.minPercent;
  }
}
