import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // {path: 'path_prefix', canActivate: [Guard], loadChildren: '@app/modules/some_module/some_module.module#SomeModule'},
  {
    path: 'questionnaire',
    loadChildren: () => import('@app/modules/questionnaire/questionnaire.module').then((m) => m.QuestionnaireModule)
  },
  { path: 'child', loadChildren: () => import('@app/modules/child/child.module').then((m) => m.ChildModule) },
  { path: 'application', loadChildren: () => import('@app/modules/application/application.module').then((m) => m.ApplicationModule) },
  { path: '', redirectTo: '/questionnaire/init', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
