import { QuestionnaireStep } from '@app/modules/questionnaire/models/passage/questionnaire-step.interface';
import { Questionnaire } from '@app/modules/questionnaire/models/questionnaire/questionnaire.model';

export class ChildAgeFormStep implements QuestionnaireStep {
  public questionnaire: Questionnaire;

  public constructor(questionnaire: Questionnaire) {
    this.questionnaire = questionnaire;
  }

  /**
   * Return route to redirect into, when step is active.
   */
  public getRoute(): string {
    return '/questionnaire/child-age';
  }
}
